import React from 'react'
import I18n from 'i18n-js'
import { Table, Badge } from 'react-bootstrap'
import { badgeAttributes } from 'utils/constants.js'
import { RiAlertFill, RiMailCheckLine, RiLinksFill } from 'react-icons/ri'
import { BsFillLightningFill, BsSquare } from "react-icons/bs";

export default function BoardLegend() {
  const visitPurposeBadge = (p) => {
    return <Badge pill className={badgeAttributes[p]["class"]}>{badgeAttributes[p]["text"]}</Badge>
  }
  const siteInactive = <span id={Math.random() } className="gold"><RiAlertFill /></span>
  const podioLinkReady = <span id={Math.random()} className="gold"><RiLinksFill /></span>
  const podioLinkEstablished = <span id={Math.random()} className="podio-teal"><RiLinksFill /></span>
  const flashReport = <span id={Math.random()} className="darkorange"><BsFillLightningFill /></span>
  const completionEmailSent = <span id={Math.random()} className="green"><RiMailCheckLine /></span>
  const ipOutputsRequestSent = <span id={Math.random()} className="purple"><RiMailCheckLine /></span>
  const missingIPOutputsRequest = <span id={Math.random()} className="purple"><BsSquare /></span>
  const missingIPInformationRequest = <span id={Math.random()} className="darkred"><BsSquare /></span>
  const missingIPFeedback = <span id={Math.random()} className="goldenrod"><BsSquare /></span>
  
  return (
    <Table>
      <tbody>
        <tr className="legend-item">
          <td className="legend-item-symbol">{missingIPInformationRequest}</td>
          <td>{I18n.t('board_legend.missing_ip_info_request')}</td>
        </tr>
        <tr className="legend-item">
          <td className="legend-item-symbol">{missingIPFeedback}</td>
          <td>{I18n.t('board_legend.missing_ip_feedback')}</td>
        </tr>
        <tr className="legend-item">
          <td className="legend-item-symbol">{missingIPOutputsRequest}</td>
          <td>{I18n.t('board_legend.missing_ip_outputs_request')}</td>
        </tr>
        <tr className="legend-item">
          <td className="legend-item-symbol">{ipOutputsRequestSent}</td>
          <td>{I18n.t('board_legend.ip_outputs_request_email_sent')}</td>
        </tr>
        <tr className="legend-item">
          <td className="legend-item-symbol">{completionEmailSent}</td>
          <td>{I18n.t('board_legend.completion_email_sent')}</td>
        </tr>
        <tr className="legend-item">
          <td className="legend-item-symbol">{podioLinkReady}</td>
          <td>{I18n.t('board_legend.podio_link_ready')}</td>
        </tr>
        <tr className="legend-item">
          <td className="legend-item-symbol">{podioLinkEstablished}</td>
          <td>{I18n.t('board_legend.podio_link_established')}</td>
        </tr>
        <tr className="legend-item">
          <td className="legend-item-symbol">{flashReport}</td>
          <td>{I18n.t('board_legend.flash_report')}</td>
        </tr>
        <tr className="legend-item">
          <td className="legend-item-symbol">{siteInactive}</td>
          <td>{I18n.t('board_legend.site_inactive')}</td>
        </tr>
        <tr className="legend-item">
          <td className="legend-item-symbol">{visitPurposeBadge('Aid Diversion Requirements Site Visit')}</td>
          <td>{I18n.t('board_legend.visit_purpose_adr')}</td>
        </tr>
        <tr className="legend-item">
          <td className="legend-item-symbol">{visitPurposeBadge('BHA-Identified Thematic Site Visit')}</td>
          <td>{I18n.t('board_legend.visit_purpose_bhat')}</td>
        </tr>
        <tr className="legend-item">
          <td className="legend-item-symbol">{visitPurposeBadge('Call Center')}</td>
          <td>{I18n.t('board_legend.visit_purpose_cc')}</td>
        </tr>
        <tr className="legend-item">
          <td className="legend-item-symbol">{visitPurposeBadge('Community Monitoring')}</td>
          <td>{I18n.t('board_legend.visit_purpose_cm')}</td>
        </tr>
        <tr className="legend-item">
          <td className="legend-item-symbol">{visitPurposeBadge('Market Scan')}</td>
          <td>{I18n.t('board_legend.visit_purpose_ms')}</td>
        </tr>
        <tr className="legend-item">
          <td className="legend-item-symbol">{visitPurposeBadge('Pre-Activity')}</td>
          <td>{I18n.t('board_legend.visit_purpose_pa')}</td>
        </tr>
        <tr className="legend-item">
          <td className="legend-item-symbol">{visitPurposeBadge('STPM')}</td>
          <td>{I18n.t('board_legend.visit_purpose_tpm')}</td>
        </tr>
      </tbody>
    </Table>
  )
}